.Reach {
  background-color: #0e3a62;
  padding: 3.2rem 9rem 3.2rem 9rem;

  @include respond(large-screen) {
    padding: 2.5rem 7rem;
  }

  @include respond(medium-screen) {
    padding: 2.5rem 6rem;
  }

  @include respond(phone) {
    padding: 3rem 2.8rem 3rem 0;
  }

  &__content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    &--item {
      text-align: center;

      &--heading {
        width: min-content;
        color: $color-primary-white;
        font-size: 4rem !important;
        color: rgba(255, 255, 255, 0.9);

        @include respond(large-screen) {
          font-size: 3rem;
        }

        @include respond(phone) {
          font-size: 1.8rem !important;
        }
      }

      &--content {
        margin-top: 2.4rem;
        font-weight: 500;
        font-size: 2.4rem;
        line-height: 3rem;
        color: #f1f1f1;

        @include respond(medium-screen) {
          font-size: $font-size-medium;
        }

        @include respond(phone) {
          margin-top: 0.8rem;
          font-size: $font-size-small-extra;
        }
      }
    }
  }
}
