.Institutes {
  padding: 6rem 0 6rem 0;

  @include respond(phone) {
    padding: 6rem 0;
  }

  &--heading {
    @include respond(phone) {
      padding: 0 4rem;
    }
  }

  text-align: center;
  background-color: $color-secondary-grey;

  &--text {
    padding: 0 14rem;

    @include respond(phone) {
      padding: 0 2.4rem;
    }
  } 

  &--content {
    margin-top: 3.4rem;
  }

  &--list {
    margin-top: 6.2rem;

    // width: 1920px;
    // overflow: hidden;

    &--profile {
      margin: 0 auto;
      width: unset !important;
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: center;

      &--img {
        margin: 0 auto;
        border-radius: 50%;
        height: 9rem;
        width: 9rem;
        -o-object-fit: cover;
        object-fit: cover;
        -o-object-position: center;
        object-position: center;
      }

      &--text {
        margin-top: 1rem;
        @include flexColumn;
      }
    }
  }
}

.Institutes--list .slick-slide {
  display: initial !important;
}

.Institutes--list .slick-arrow {
  display: none !important;
}

.Institutes--list .slick-dots > * {
  display: none !important;
}
