.newForm {
  display: flex;
  flex-direction: column;

  &__header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 2.4rem;

    @include respond(phone) {
      border-bottom: 1px solid #e5e5e5;
    }

    &--para {
      width: 50%;
      font-style: normal;
      font-weight: bold;
      font-size: 2.4rem;
      line-height: 150%;
      text-align: center;
      color: rgba(0, 0, 0, 0.87);

      @include respond(phone) {
        width: 50%;
      }
    }

    &--img {
      display: none;
 
      @include respond(phone) {
        display: block;
      }
    }
  }

  &__label {
    width: 100%;
    font-style: normal;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 150%;
    color: #212121;
    margin-bottom: 0.8rem;
    text-align: left;
  }

  &__iam {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1.6rem;

    &--para {
      width: 30%;
      border: 1px solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 4px;
      font-style: normal;
      font-weight: normal;
      font-size: 1.6rem;
      line-height: 125%;
      text-align: center;
      color: #000000;
      padding: 1.4rem 0;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__input,
  &__inputDiv {
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    padding: 1rem;
    margin-bottom: 1.6rem;

    &:focus {
      outline: none;
    }

    &--error {
      margin-top: -1.6rem;
      color: #e50303;
    }
  }

  &__select {
    width: 100%;
    margin-bottom: 1.8rem;

    &:focus {
      outline: none;
    }
  }

  &__inputDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    &--code {
      width: 9%;
      padding-right: 1rem;
      margin-right: 1rem;
      border-right: 1px solid hsl(0, 0%, 80%);

      @include respond(phone) {
        width: 12%;
      }
    }
    &--input {
      width: 90%;
      border: none;

      &:focus {
        outline: none;
      }
    }
  }

  &__button {
    width: 100%;
    background: #b2b2b2;
    border-radius: 4px;
    font-style: normal;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 125%;
    color: #ffffff;
    padding: 1.4rem 0;

    &:hover {
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }
  }
}

.css-1pahdxg-control {
  box-shadow: 0 0 0 1px hsl(0, 0%, 80%) !important;
  border-color: hsl(0, 0%, 80%) !important;

  &:hover {
    border-color: hsl(0, 0%, 80%) !important;
  }
}

.TutorForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__progress {
    margin-bottom: 2.4rem;
  }

  &__header {
    width: 100%;
    font-style: normal;
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 150%;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 2.4rem;
  }

  &__label {
    width: 100%;
    font-style: normal;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 150%;
    color: #212121;
    margin-bottom: 0.8rem;
    text-align: left;
  }

  &__select {
    width: 100%;
    margin-bottom: 1.6rem;

    &:focus {
      outline: none;
    }
  }

  &__iam {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 1.6rem;

    &--para {
      border: 1px solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 4px;
      font-style: normal;
      font-weight: normal;
      font-size: 1.6rem;
      line-height: 125%;
      text-align: center;
      color: #000000;
      padding: 1.4rem 2rem;
      margin-right: 1rem;
      margin-bottom: 1rem;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__iam2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1.6rem;

    &--para {
      width: 30%;
      border: 1px solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 4px;
      font-style: normal;
      font-weight: normal;
      font-size: 1.6rem;
      line-height: 125%;
      text-align: center;
      color: #000000;
      padding: 1.4rem 0;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__button {
    width: 100%;
    background: #b2b2b2;
    border-radius: 4px;
    font-style: normal;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 125%;
    color: #ffffff;
    padding: 1.4rem 0;
    margin-top: 2.4rem;

    &:hover {
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }
  }
}
