.LifeStory {
    padding: 6rem 12rem;
    text-align: center;
    background-color: $color-secondary-grey;
    position: relative;

    @include respond(phone) {
        padding: 6rem 0rem;
    }

    &--text {
        padding: 0 10rem;

        @include respond(ipad) {
            padding: 0 2.4rem;
        }

    }

    &__content {
        margin-top: 2.4rem;
        position: relative;

        @include respond(phone) {
            padding:0 0.1rem;
        }
    }

    &__video {
        padding: 10rem 0 5rem 0;
    }
}


.video-play-button {
    position: absolute;
    z-index: 10;
    //top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    display: block;
    width: 32px;
    height: 44px;
    background: #009ae0;
    border-radius: 50%;
    padding: 18px 20px 18px 28px;
}

.video-play-button:before {
    content: "";
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 80px;
    height: 80px;
    background: #009ae0;
    border-radius: 50%;
    -webkit-animation: pulse-border 1500ms ease-out infinite;
    animation: pulse-border 1500ms ease-out infinite;
}

.video-play-button:after {
    content: "";
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 80px;
    height: 80px;
    background: #009ae0;
    border-radius: 50%;
    -webkit-transition: all 200ms;
    -o-transition: all 200ms;
    transition: all 200ms;
}

.video-play-button:hover:after {
    background-color: darken(#009ae0, 10%);
}

.video-play-button img {
    position: relative;
    z-index: 3;
    max-width: 100%;
    width: auto;
    height: auto;
}

.video-play-button span {
    display: block;
    position: relative;
    z-index: 3;
    width: 0;
    height: 0;
    border-left: 32px solid #fff;
    border-top: 22px solid transparent;
    border-bottom: 22px solid transparent;
}

@-webkit-keyframes pulse-border {
    0% {
        -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
        opacity: 0;
    }
}

@keyframes pulse-border {
    0% {
        -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
        opacity: 0;
    }
}



.video-overlay {
    position: fixed;
    z-index: -1;
    // top: 0;
    // bottom: 0;
    // left: 0;
    // right: 0;
    // background: rgba(0, 0, 0, 0.80);
    opacity: 0;
    -webkit-transition: all ease 500ms;
    -o-transition: all ease 500ms;
    transition: all ease 500ms;
}

.video-overlay.open {
    position: fixed;
    z-index: 1000;
    opacity: 1;
}

.video-overlay-close {
    position: absolute;
    z-index: 1000;
    top: 15px;
    right: 20px;
    font-size: 36px;
    line-height: 1;
    font-weight: 400;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    -webkit-transition: all 200ms;
    -o-transition: all 200ms;
    transition: all 200ms;
}

.video-overlay-close:hover {
    color: #009ae0;
}

.video-overlay iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    /* width: 90%; */
    /* height: auto; */
    -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.75);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.75);
}

.iframeContainer {

    @include flexCenter
}

.iframeVideo {
    border: none;
    outline: none;
    height: 60rem;
    width: 90rem;


    @include respond(ipad) {
        width: 60rem;
        height: 40rem;
    }

    @include respond(phone) {
        width: 39rem;
        height: 26rem;
    }

}