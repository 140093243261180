.Warriors {
  padding: 2.4rem 7%;
  margin-top: 2.4rem;

  display: flex;
  flex-direction: column;
  /* align-items: center; */
}
.Warriors__Top {
  font-size: 4.8rem;
  font-weight: 700;
  line-height: 7.2rem;

  font-family: inherit;

  margin-bottom: 2.4rem;

  color: #000;

  text-align: center;
}

@media (max-width: 767px) {
  .Warriors__Top {
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 3.2rem;
  }
}
