.Carousel {
    &__pic {


        &--img {
            height: 64.5rem;
            width: 63.4rem;

            @include respond(phone) {
                margin-bottom: 1rem;
                height: 34rem;
                width: 38rem;
            }

            &--small {
                display: none !important;


            }
        }
    }

    &__content {
        margin-left: 7rem;
        text-align: left;



        @include respond(ipad) {
            margin-left: 0;
            padding: 0 2.4rem;
            text-align: center;

        }

        &--heading {
            text-align: left;

            @include respond(phone) {
                text-align: center;
            }
        }

        &--content {
            margin-top: 3.4rem;



            &--text {
                @include respond(phone) {
                    padding: 0rem;
                }
            }

            @include respond(phone) {
                text-align: center;
                // padding:0 2.4rem;
            }

            &--button {
                margin-top: 4.6rem;
            }
        }

        &--testimonial {
            border-top: 1px solid #008489;
            margin-top: 4.6rem;
            padding-top: 3.7rem;
            @include flexRow;

            @include respond(medium-screen) {
                @include flexColumn;
                -webkit-box-orient: vertical !important;
                -webkit-box-direction: reverse !important;
                -ms-flex-direction: column-reverse !important;
                flex-direction: column-reverse !important;
            }



            &--pic {
                @include flexColumn;
                text-align: center;
                min-width: 15rem;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;

                @include respond(phone) {
                    margin-top: 2rem;
                }

                &--img {
                    width: 9rem;
                    height: 9rem;
                    border-radius: 50%;
                    border: 1px solid #e5e5e5;
                    -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.12);
                    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.12);
                }

                &--name {
                    margin-top: 2rem;
                    font-size: $font-size-medium;
                    font-weight: $font-weight-medium;
                    line-height: 23px;
                }

                &--org {
                    font-size: $font-size-small-extra;
                    font-weight: $font-weight-medium;
                    line-height: 18x;
                    color: $color-primary-green
                }
            }

            &--text {
                margin-left: 2.4rem;
                $font-size: $font-size-small;
                // color: $color-tertiary-grey;
                line-height: 2.4rem;
                position: relative;

                @include respond(ipad) {
                    padding: 0rem 1rem 0 2rem;
                }

                &--quotes {
                    position: absolute;
                    top: -1rem;
                    left: -4rem;

                    @include respond(ipad) {
                        left: -3rem;
                    }

                }
            }
        }
    }

}