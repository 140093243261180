.successModal {
  display: flex;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);

  &__container {
    margin: auto;
    width: 30%;
    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.24);
    border-radius: 16px;
    padding: 3.2rem 7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    @include respond(phone){
        width: 100%;
        border-radius: 16px 16px 0 0;
        position: fixed;
        bottom: 0;
        padding-bottom: 8rem;
    }

    &--header {
      width: 100%;
      position: relative;
      &__img {
        position: absolute;
        top:0;
        left: 106%;

        &:hover {
            cursor: pointer;
        }
      }
      margin-bottom: 2rem;
    }

    &--head {
      font-style: normal;
      font-weight: bold;
      font-size: 2.4rem;
      line-height: 150%;
      margin-top: 3.2rem;
    }

    &--para {
      font-style: normal;
      font-weight: normal;
      font-size: 1.8rem;
      line-height: 27px;
      color: #000000;
      margin-top: 1.6rem;
    }
  }
}
