@import './scss/main.scss';

.ui.dropdown {
  background: #009ae0;
  color: #fff;
  padding: 1rem 1.6rem;
  border-radius: 4px;
  font-size: 1.6rem;
}

.ui.dropdown .menu {
  top: 120%;
  left: -7rem !important;
}

.ui.dropdown .menu > .item {
  padding: 1.5rem !important;
}

.ui.dropdown .menu > .item > a {
  color: black;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.8rem;
}

.callButton {
  position: fixed;
  right: 2rem;
  bottom: 15vh;
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  background-color: #009ae0;
  cursor: pointer;
  display: grid;
  place-items: center;

  @include respond(phone) {
    width: 7.5rem;
    height: 7.5rem;
    right: 2.5rem;
  }
}

.callButton__img {
  width: 3rem;
  height: 3rem;

  @include respond(phone) {
    width: 4rem;
    height: 4rem;
  }
}

.book-demo-wrapper {
  padding: 16px 12px;
  background: #fffffe !important;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  bottom: 0;

  z-index: 99;
  text-align: center;
  cursor: pointer;
  -webkit-transition: 1s ease;
  transition: 1s ease;
  border: none;
  color: #fff;
}
.book-demo {
  width: inherit;
  /* height: 40px; */
  padding: 0 8px;
  line-height: 40px;
  /* position: fixed;
  bottom: 0;
  left: 0; */
  font-size: 16px;
  border-radius: 4px;

  background: #009ae0 !important;
}

.closeBtn {
  bottom: -100%;
}

#chat-bot-launcher-container {
  /* top: 83% !important; */
  bottom: 8% !important;
  display: flex;
}


.whatsappCTAMobile_footer{
  // padding: 16px 12px;
  // background: #fffffe !important;
  // width: 100%;

  display: none;
  justify-content: center;
  align-items: center;

  position: fixed;
  bottom: 80px;
  right:12px;
  z-index: 99;
  text-align: center;
  cursor: pointer;
  -webkit-transition: 1s ease;
  transition: 1s ease;
  border: none;
  color: #fff;
}

.whatsappCTAMobile{
  width: 100%;
  height: 46px;
  width: 46px;
  padding:0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background: #33CC66;
  border-radius: 50%;
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 22px;
  color: #FFFFFF;
  text-decoration: none;
}
.whatsappCTAMobile:hover{
  color:#FFFFFF;
}
.whatsappCTAMobile img{
  // margin-right:8px;
}

@media(max-width:700px){
  .whatsappCTAMobile_footer{
      display: flex;
  }


#chat-bot-launcher-container {
  /* top: 83% !important; */
  bottom: 8% !important;
  display: none;
}

}