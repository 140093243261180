.pointImg {
  height: 30px;
  width: 30px;

  @include respond(phone) {
    height: 20px;
    width: 20px;
  }
}
.TextContentGroup {
  margin-top: 60px;

  @include respond(phone) {
    margin-top: 16px;
  }
}

.landing {
  // background-image: url("../../images/background.svg");
  background-position: center;
  margin-top: 7rem;
  padding: 6.5rem 0;

  @include respond(phone) {
    padding: 0;
    margin-top: 3rem;
  }

  &__content {
    grid-column: second-start/second-end;
    @include flexColumn;
    // align-self: center;
    position: relative;
    color: #fff;
    // font-family: 'Nunito Sans', sans-serif;
    //   animation: easyLeft 0.3s ease-in;

    @include respond(phone) {
      //     height: 20rem;
      text-align: center;
    }

    &--Item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 36px;

      @include respond(phone) {
        margin-top: 16px;
      }

      &-Text {
        color: #016999ce;
        font-weight: 500;
        text-align: center;

        &-heading {
          font-size: 54px;
          line-height: 60px;
          font-weight: 700;
          margin-bottom: 16px;

          @include respond(phone) {
            font-size: 28px;
            line-height: 28px;
            margin-bottom: 4px;
          }
        }
        &-normalText {
          font-size: 27px;
          font-weight: 500;
          line-height: 34px;

          @include respond(phone) {
            font-size: 18px;
            line-height: 21px;
          }
        }
      }
      &-TextContent {
        display: flex;
        flex-direction: row;
        margin: 16px 0;

        @include respond(phone) {
          margin: 6px 0;
        }

        &-point {
          margin-left: 8px;
          font-size: 24px;
          font-weight: 500;
          line-height: 30px;
          color: #016999ce;

          @include respond(phone) {
            font-size: 16px;
            line-height: 20px;
          }
        }
      }
    }

    &--main {
      font-size: $font-size-heading-mega;
      font-weight: $font-weight-bold;
      line-height: 6rem;
      //display: none;

      @include respond(phone) {
        margin-top: 3rem;
        font-size: 3.5rem;
        padding: 2.7rem;
        line-height: 5rem;
        font-weight: $font-weight-medium;
        display: block;
        text-align: left;
      }

      &--corona {
        font-size: 6rem;
        display: flex;

        @include respond(phone) {
          font-size: 7rem;
          margin-top: 8rem;
          padding: 0;
          @include flexCenter;
          text-align: center;
          flex-direction: column;
        }
      }

      &--corona > div {
        @include respond(phone) {
          margin-bottom: 2rem;
        }
      }
    }

    &--sub {
      margin-top: 3.4rem;
      font-size: 2.4rem;
      font-weight: $font-weight-medium;
      line-height: 3rem;
      // display: none;

      @include respond(phone) {
        // display: none;
        font-size: 2.4rem;
        margin-top: 2rem;
        padding: 0 2.2rem 2.7rem;
        display: block;
        text-align: left;
        width: 60%;
        line-height: 4rem;
      }

      &--corona {
        font-size: 3.2rem !important;
        line-height: 4rem !important;

        @include respond(phone) {
          margin-top: 4rem;
          padding: 0 2rem;
        }
      }

      &--hashtag {
        margin-top: 1rem !important;
        font-weight: $font-weight-light;

        @include respond(phone) {
          font-size: 2.5rem;
        }
      }

      &--prompt {
        margin-top: 5rem !important;
        font-weight: $font-weight-light;
        font-size: 4rem;

        &--2 {
          margin-bottom: 3rem;
        }

        @include respond(phone) {
          margin-top: 2rem !important;
          padding: 0;
          font-size: 3.2rem;
          font-weight: $font-weight-medium;
          text-align: left;
        }
      }
    }

    &--tnc {
      display: none;

      @include respond(phone) {
        display: block;
        text-align: right;
        position: absolute;
        right: 2rem;
        bottom: 0rem;
      }
    }
  }

  &__form {
    background-color: $color-primary-white;
    max-width: 46rem;
    border-radius: 4px;
    margin-left: 12rem;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    height: fit-content;
    padding: 1.8rem;

    //  animation: easyRight 0.3s ease-in;

    @include respond(tab-land) {
      margin-left: 9.5rem;
    }

    @include respond(medium-screen) {
      margin-left: 7rem;
    }

    @include respond(tab-port) {
      padding: 2.7rem 3.2rem;
    }

    @include respond(ipad) {
      padding: 2.7rem 1.2rem;
    }

    @include respond(phone) {
      margin: 0 !important;
      //min-width: 90%;
      text-align: left;
      max-width: unset;
      padding: 2.7rem 2.6rem;
    }

    &--header {
      &--heading {
        font-size: 3.2rem;
        font-weight: $font-weight-bold;
        line-height: 4rem;

        @include respond(tab-land) {
          font-size: 3.6rem;
        }

        @include respond(ipad) {
          line-height: 4rem;
        }

        @include respond(phone) {
          font-size: 2.3rem;
          font-weight: 700;
        }
      }

      &--content {
        margin-top: 3rem;
        font-size: $font-size-small;
        line-height: 2.4rem;
        font-weight: $font-weight-medium;

        @include respond(ipad) {
          margin-top: 2.7rem;
        }

        @include respond(phone) {
          margin-top: 1rem;
        }
      }
    }
  }
}

.landing__Image {
  width: 55rem;
  height: 45rem;
  align-self: center;

  @include respond(phone) {
    // width:30rem;
    // height: 30rem;
    display: none;
  }
}

.instantFix {
  width: fit-content;
  font-size: 2.5rem;

  @include respond(phone) {
    // align-self: center;

    font-size: 2rem;
  }
}

.instantFix2 {
  position: absolute;
  width: fit-content;
  top: 5rem;
  right: -8rem;
  padding: 1.6rem 2.4rem;
}

@media only screen and (min-width: 1440px) {
  // .landing__form {
  //     max-height: 45rem;
  // }
}

@media only screen and (min-width: 1925px) {
  .landing__form {
    margin-top: 6rem;
  }
}

@media only screen and (max-width: 1925px) {
  .landing__form {
    margin-top: 6rem;
  }
}

@media only screen and (max-width: 1875px) {
  .landing__form {
    margin-top: 4rem;
  }
}

@media only screen and (max-width: 1825px) {
  .landing__form {
    margin-top: 2rem;
  }
}

@media only screen and (width: 1800px) {
  .landing__form {
    margin-top: 1rem;
  }
}

@media only screen and (max-width: 1799px) {
  .landing__form {
    margin-top: 13rem;
  }
}

@media only screen and (max-width: 1775px) {
  .landing__form {
    margin-top: 12rem;
  }
}

@media only screen and (max-width: 1750px) {
  .landing__form {
    margin-top: 11rem;
  }
}

@media only screen and (max-width: 1725px) {
  .landing__form {
    margin-top: 10rem;
  }
}

@media only screen and (max-width: 1700px) {
  .landing__form {
    margin-top: 9rem;
  }
}

@media only screen and (max-width: 1675px) {
  .landing__form {
    margin-top: 8rem;
  }
}

@media only screen and (max-width: 1650px) {
  .landing__form {
    margin-top: 7rem;
  }
}

@media only screen and (max-width: 1625px) {
  .landing__form {
    margin-top: 6rem;
  }
}

@media only screen and (max-width: 1600px) {
  .landing__form {
    margin-top: 5rem;
  }
}

@media only screen and (max-width: 1575px) {
  .landing__form {
    margin-top: 4rem;
  }
}

@media only screen and (max-width: 1550px) {
  .landing__form {
    margin-top: 3rem;
  }
}

@media only screen and (max-width: 1525px) {
  .landing__form {
    margin-top: 2rem;
  }
}

@media only screen and (max-width: 1500px) {
  .landing__form {
    margin-top: 1rem;
  }
}

.line-1 {
  white-space: nowrap;
  overflow: hidden;
}

/* Animation */
.anim-typewriter {
  animation: typewriter 4s steps(44) 1s 1 normal both,
    blinkTextCursor 500ms steps(44) infinite normal;
}

@keyframes typewriter {
  from {
    width: 0;
  }

  to {
    width: 24em;
  }
}

@keyframes blinkTextCursor {
  from {
    border-right-color: rgba(255, 255, 255, 0.75);
  }

  to {
    border-right-color: transparent;
  }
}

.landing__content--sub--prompt--2 {
  @include respond(phone) {
    font-size: 2.5rem;
  }
}

.centerAlignmentNew {
  position: absolute;
  bottom: 32vh;
  right: 1rem;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
