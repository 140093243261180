.TestimonialCard {
  padding: 12rem;
  padding-bottom: 8rem;

  @include respond(phone) {
    padding: 2.2rem;
  }

  &__Container {
    background: rgba(26, 58, 95, 0.9);
    mix-blend-mode: normal;
    padding: 4rem;
    border-radius: 10px;
    display: grid;
    grid-template-columns: 2.5fr 1fr;
    background-image: url("https://d2sqt6igbl7quy.cloudfront.net/cams/apr21/testimonialBG.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @include respond(phone) {
      grid-template-columns: 1fr;
      padding: 2rem;
    }

    &--Col1 {
      width: 80%;
      align-self: center;
      text-align: left;

      @include respond(phone) {
        width: 100%;
      }

      &__Header {
        font-style: normal;
        font-weight: 900;
        font-size: 4rem;
        line-height: 150%;
        color: #ffffff;

        @include respond(phone) {
          font-size: 3.2rem;
        }
      }
      &__Para {
        margin-top: 3.2rem;
        font-weight: normal;
        font-size: 1.6rem;
        line-height: 150%;
        color: #ffffff;

        @include respond(phone) {
            margin-bottom: 3.2rem;
        }
      }
    }

    &--Col2 {
      background: #ffffff;
      border-radius: 6px;

      &__Img {
        background: #dbf6a2;
        border-radius: 6px 6px 0px 0px;
      }

      &__Img img {
        width: 100%;
      }

      &__Quote {
        padding: 0.8rem 1.6rem;

        &--Row1 {
          display: flex;
          justify-content: flex-start;
        }

        &--Row2 {
          font-style: normal;
          font-weight: normal;
          font-size: 1.6rem;
          line-height: 150%;
          color: rgba(0, 0, 0, 0.87);
          padding: 0.9rem 1.6rem;
          text-align: left;
          display: flex;
          justify-content: flex-start;
        }

        &--Row3 {
          display: flex;
          justify-content: flex-end;

          &__QuoteImg {
            transform: rotate(180deg);
          }
        }
        &--Row4 {
          font-style: normal;
          font-weight: 900;
          font-size: 1.4rem;
          line-height: 157%;
          display: flex;
          justify-content: flex-start;
          text-align: left;
          padding: 0.9rem 1.6rem;
          color: #000000;
        }
      }

      &__Read {
        padding: 1.4rem;
        border-top: 1px solid #e5e5e5;
        font-style: normal;
        font-weight: normal;
        font-size: 1.4rem;
        line-height: 150%;
        color: rgb(0, 154, 224);

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
